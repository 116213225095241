@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500&display=swap");

@font-face {
	font-family: "Segoe UI";
	src: url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.eot");
	/* IE9*/
	src: url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.woff2") format("woff2"), /* chrome、firefox */ url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.woff") format("woff"), /* chrome、firefox */ url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url("../fonts/segoui/623c7118249e082fe87a78e08506cb4b.svg#Segoe UI") format("svg");
	/* iOS 4.1- */
}

@font-face {
	font-family: "SegoeUIBold";
	src: url("../fonts/segoui/FontsFreeNetSegoeUIBold.eot");
	src: url("../fonts/segoui/FontsFreeNetSegoeUIBold.eot") format("embedded-opentype"), url("../fonts/segoui/FontsFreeNetSegoeUIBold.woff2") format("woff2"), url("../fonts/segoui/FontsFreeNetSegoeUIBold.woff") format("woff"), url("../fonts/segoui/FontsFreeNetSegoeUIBold.ttf") format("truetype"), url("../fonts/segoui/FontsFreeNetSegoeUIBold.svg#FontsFreeNetSegoeUIBold") format("svg");
}

@font-face {
	font-family: "SegoeUI_Medium";
	src: url("../fonts/segoui/SegoeUI_Medium.eot");
	src: url("../fonts/segoui//SegoeUI_Medium.eot") format("embedded-opentype"), url("../fonts/segoui/SegoeUI_Medium.woff2") format("woff2"), url("../fonts/segoui/SegoeUI_Medium.woff") format("woff"), url("../fonts/segoui/SegoeUI_Medium.ttf") format("truetype"), url("../fonts/segoui/SegoeUI_Medium.svg#SegoeUI_Medium") format("svg");
}

html {
	font-weight: 100;
	font-size: 14px;
}

body {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-variant: tabular-nums;
	line-height: 1.5715;
	background-color: #fff;
	font-feature-settings: "tnum", "tnum";
	overflow-x: hidden;
	text-align: left;
}

body {
	font-family: "Segoe UI" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: "Nunito Sans", sans-serif !important;
	font-weight: 600 !important;
	margin-bottom: 0.5em;
}

.form-control {
	min-height: 45px;
}

.btn {
	padding: 0.375rem 1rem;
}

a:active,
a:hover {
	text-decoration: none;
	outline: 0;
}

a:hover {
	color: inherit;
	text-decoration: none;
}

.modal-100w {
	max-width: 100%;
}

.modal-90w {
	max-width: 90%;
}

.modal-80w {
	max-width: 80%;
}

.modal-70w {
	max-width: 70%;
}

.form-control:focus {
	box-shadow: none;
}
.image-post-box-aff-inr-inr .post-image {
	display: block;
	height: 100%;
	width: 100%;
	border-radius: 0px !important;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: 50% 0;
	object-position: 50% 0;
  }
  .image-post-box-aff-inr-inr video {
	height: auto;
	max-width: 100%;
  }

.cat-box {
	text-align: center;
	margin-top: 20px;
	margin-top: 10px;
	//   padding-right: 20px !important;
	//   padding-left: 20px !important;
}
.cat-box .cat-image {
	object-fit: cover;
	border-radius: 10px;
	border: 1px solid;
	width: 100%;
	height: 55px;
}
.cat-lable {
	font-weight: 700;
	font-family: "SegoeUIBold";
	font-size: 0.8rem;
	padding-top: 5px;
	padding-bottom: 15px;
}
.cat-box .action {
	transition: 0.5s ease;
	opacity: 0;
	position: absolute;
	top: 29%;
	// top: 35%;
	left: 48%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
	display: inline-flex;
}
.cat-box:hover .cat-image {
	opacity: 0.3;
}
.cat-box .edit-icon {
	text-decoration: none;
	color: #000;
	cursor: pointer;
}
.cat-box .edit-icon span {
	margin-left: 5px;
}
.cat-box:hover .action {
	opacity: 1;
}
.profile-icon {
	object-fit: cover;
}
.edit-cat-icon {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	margin: 10px 0;
	border: 1px solid #c1c1c1;
}

.select-image {
	background-color: #010b40 !important;
    color: #fff;
    border-color: #010b40;
    font-family: Nunito Sans,sans-serif!important;
    font-size: 15px;
    font-weight: 700;
    height: 38px;
    margin: 0 5px;
    min-width: 120px;
    padding: 6.4px 25px;
    border: 1px solid #010b40;
    border-radius: 3px;
	&:hover{
		background-color: #000;
		border-color: #000;
		color: #FFF;
	}
	label{
		margin: 0;
		cursor: pointer;
	}
}

//loader
.ant-spin-lg .ant-spin-dot i,
.ant-spin-sm .ant-spin-dot i,
.ant-spin-dot-item {
	background-color: #010b40;
}